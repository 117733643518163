export const formatToTime = (toTime: string): string => {
	if (Number.isNaN(Date.parse(toTime))) {
		return "";
	}
	const options: Intl.DateTimeFormatOptions = {
		weekday: "long",
		day: "numeric",
		month: "long",
		hour: "numeric",
		hourCycle: "h12",
		dayPeriod: "long",
	};

	return new Date(toTime).toLocaleString("cs-cz", options);
};
