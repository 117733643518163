import { Alert, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";

import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL, HeadingXL } from "../../components/Typography";
import { formatToTime } from "../../functions/formatTime";
import { useParams } from "../../hooks/useParams";

const MaintenancePage = (): JSX.Element => {
	const [time] = useParams({ name: "time", required: false });
	const formattedTime = formatToTime(time || "");
	const isDesktop = useIsDesktop();
	const image = useStaticQuery(
		graphql`
			query MaintenaceImage {
				desktop: file(relativePath: { eq: "outlet.png" }) {
					childImageSharp {
						fixed(quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Stack
			sx={{
				display: "flex",
				flexDirection: "row",
				gap: "50px",
				alignItems: "center",
			}}
		>
			<Alert
				sx={{
					flexDirection: "row-reverse",
					height: "min-content",
					mx: { md: 0, sm: 4, xs: 4, xxs: 4 },
					py: 3,
					px: 4,
					boxShadow: "far",
					border: "1px solid",
					borderColor: "grey.light",
					backgroundColor: "white.main",
					".MuiAlert-message": {
						flexGrow: 1, // for wide layouts
					},
				}}
				icon={false}
				severity={undefined}
			>
				<HeadingXL>Portál si právě dává pauzu</HeadingXL>
				<HeadingL sx={{ mb: 1 }} scaleDown>
					Pěkný den,
				</HeadingL>
				{!formattedTime && (
					<BodyTextM>
						nyní se vám musím na chvíli vypnout, protože se připravuji na
						spuštění nové a lepší verze. Přihlásit se budete moci do 15 minut,
						až budu připravený.
					</BodyTextM>
				)}
				{formattedTime && (
					<BodyTextM>
						nyní se vám musím vypnout, protože se připravuji na spuštění nové a
						lepší verze. Očekávaný čas opětovného spuštění je {formattedTime},
						až bude vše připravené.
					</BodyTextM>
				)}
				<BodyTextM sx={{ display: "block" }}>
					<br />
					Děkuji za pochopení,
				</BodyTextM>
				<HeadingL scaleDown>
					<br />
					Portál NN.
				</HeadingL>
			</Alert>
			{isDesktop ? (
				<img src={image.desktop.childImageSharp.fixed.src} alt="" />
			) : null}
		</Stack>
	);
};

export default MaintenancePage;

export const Head = (): JSX.Element => (
	<SEO title="Portál si dává pauzu">
		<meta name="robots" content="noindex" />
	</SEO>
);
